import { ContentContainer } from './content-container.model'
import { FileContext } from './file-context.model'
import { MaterialGroup } from './material-group.model'
import { AttachmentCategory } from './attachment-category.model'
import { MaterialCategory } from './material-category.model'

export class Craft implements ContentContainer {
  constructor(public id?: number,
              public originalId?: number,
              public orderId?: number,
              public heading?: string,
              public textContent?: string,
              public linkedAttachmentCategories?: AttachmentCategory[],
              public fileContexts?: FileContext[],
              public hasProductList?: boolean,
              public productLists?: FileContext[],
              public materialGroups?: MaterialGroup[],
              public materialCategories?: MaterialCategory[]) {
      if (!this.fileContexts) {
          this.fileContexts = []
      }
      if (!this.linkedAttachmentCategories) {
          this.linkedAttachmentCategories = []
      }
      if (!this.materialGroups) {
          this.materialGroups = []
      }
      if (!this.materialCategories) {
        this.materialCategories = []
      }
  }
    get images() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('image/')) : []
    }

    get pdfs() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('application/pdf')) : []
    }

    get files() {
        return this.fileContexts
            ? this.fileContexts.filter((fileContext) => !(fileContext.file.fileType.startsWith('application/pdf') || fileContext.file.fileType.startsWith('image/')))
            : []
    }
}
